import { ChangeEvent, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StatesAlpha2List } from "../statesAlpha2List";

export type StrafregisterProps = {
    land: string
}

export default function StrafregisterInput(props: StrafregisterProps) {

    const [fileInvalidMessage, setFileInvalidMessage] = useState('');

    const checkFile = (e: ChangeEvent<HTMLInputElement>) => {
        checkFileElement(e.target);
    }

    const checkFileElement = (target: HTMLInputElement) => {
        if (target.files !== null && target.files?.length > 0) {
            const file = target.files[0];
            if (file.size > 2048000) {
                target.setCustomValidity('Datei zu groß');
                setFileInvalidMessage('Datei muss kleiner als 2 MB sein');
            }
            else {
                target.setCustomValidity('');
                setFileInvalidMessage('');
            }
        }
    }
    return <Row>
        <Col className='py-3'>
            <Form.Group>
                <Form.Label>Strafregisterauszug {StatesAlpha2List.ALPHA2CODES[props.land]}</Form.Label>
                <Form.Control type='file' name={'strafregister_' + props.land} accept='image/jpeg,image/png,image/gif,application/pdf' required onChange={checkFile}></Form.Control>
                <Form.Control.Feedback type='invalid' >
                    {fileInvalidMessage}
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
    </Row>
}