import { Button, Col, Row } from "react-bootstrap";
import { Arbeit } from "../AddArbeitComponent/AddArbeitComponent";
import moment from "moment";
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { Constants } from "../constants";


export default function ArbeitsZeile(props: Readonly<{ arbeit: Arbeit, index: number, deleteCallback: (index: number) => void }>) {


    const translateArt = (artNummer: string): string => {
        if (artNummer === "0")
            return "Beschäftigungsverhältnis";
        else if (artNummer === "1")
            return "Aus- und Weiterbildung";
        else if (artNummer === "2")
            return "Arbeitslos";
        else if (artNummer === "3")
            return "Selbständig";
        else if (artNummer === "4")
            return "nicht zutreffend (Lücke)";
        return "FEHLER";
    }

    return <Row className="border">
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{translateArt(props.arbeit.art)}</Col>
        <Col>{props.arbeit.organisation}</Col>
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{props.arbeit.taetigkeit}</Col>
        <Col>{moment(props.arbeit.von).format('DD.MM.YYYY')}</Col>
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{props.arbeit.bis ? moment(props.arbeit.bis).format('DD.MM.YYYY') : 'aktuell'}</Col>
        <Col><Button onClick={() => props.deleteCallback(props.index)}><FaTrash /></Button> </Col>
    </Row>
}