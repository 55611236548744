import { Button, Col, Row } from "react-bootstrap";
import { Wohnsitz } from "../AddWohnsitzComponent/AddWohnsitzComponent";
import moment from "moment";
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { Constants } from "../constants";



export default function WohnsitzZeile(props: Readonly<{ readonly wohnsitz: Wohnsitz, readonly index: number, deleteCallback: (index: number) => void }>) {

    return <Row className="border" >
        <Col>{props.wohnsitz.land}</Col>
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{props.wohnsitz.plz}</Col>
        <Col>{props.wohnsitz.ort}</Col>
        <Col>{props.wohnsitz.strasse}</Col>
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{props.wohnsitz.hausnummer}</Col>
        <Col>{moment(props.wohnsitz.von).format('DD.MM.YYYY')}</Col>
        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>{props.wohnsitz.bis ? moment(props.wohnsitz.bis).format('DD.MM.YYYY') : 'aktuell'}</Col>
        <Col><Button onClick={() => props.deleteCallback(props.index)}><FaTrash /></Button> </Col>
    </Row>
}
