import { FaAsterisk } from "@react-icons/all-files/fa/FaAsterisk";
import moment from "moment";
import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap"


export type Arbeit = {
    art: string,
    organisation: string,
    taetigkeit: string,
    von: Date,
    bis?: Date
}

export type AddArbeitComponentProps = {
    callbackOnAdd: (arbeit: Arbeit) => void,
    callbackAbort: () => void
}

export default function AddArbeitComponent(props: AddArbeitComponentProps) {

    const [validated, setValidated] = useState(false);
    const [fromInvalidMessage, setFromInvalidMessage] = useState("");
    const [toInvalidMessage, setToInvalidMessage] = useState("");
    const [artInvalidMessage, setArtInvalidMessage] = useState("");



    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {

            let correct = true;
            const vonDate = moment(form.von.value);
            if (!vonDate.isValid()) {
                setFromInvalidMessage("Von Datum muss im Format YYYY-MM angegeben werden.");
                form.von.setCustomValidity("VonDatum");
                correct = false;
            }
            else if (vonDate.isAfter(moment())) {
                setFromInvalidMessage("Von Datum darf nicht in der Zukunft liegen.");
                form.von.setCustomValidity("VonDatum");
                correct = false;
            }
            else {
                form.von.setCustomValidity("");
            }
            let bisDate = null;
            if (form.bis.value)
                bisDate = moment(form.bis.value);


            if (bisDate != null && !bisDate.isValid()) {
                setToInvalidMessage("Bis Datum muss im Format YYYY-MM angegeben werden oder leer sein, wenn Wohnsitz aktuell ist.");
                correct = false;
            }
            else if (bisDate != null && bisDate.isBefore(vonDate)) {
                setToInvalidMessage("Bis Datum muss nach Von-Datum liegen.");
                correct = false;
            }

            if (form.art.value === "-1") {
                setArtInvalidMessage("Bitte Art auswählen");
                form.art.setCustomValidity("Art");
                correct = false;
            }


            const arbeit: Arbeit = {
                art: form.art.value,
                organisation: form.organisation.value,
                taetigkeit: form.taetigkeit.value,
                von: vonDate.toDate(),
                bis: bisDate?.toDate()

            }
            if (correct)
                props.callbackOnAdd(arbeit);
        }

        setValidated(true);

    };

    const handleArtChange = (e: ChangeEvent<HTMLSelectElement>) => {
        resetFormValidity(e);
        if (e.target.value === "2" || e.target.value === "3" || e.target.value === "4") {
            formRef.current!.organisation.value = 'Keine Angaben';
            formRef.current!.taetigkeit.value = 'Keine Angaben';
        }
        else {
            if (formRef.current!.organisation.value === 'Keine Angaben')
                formRef.current!.organisation.value = '';
            if (formRef.current!.taetigkeit.value === 'Keine Angaben')
                formRef.current!.taetigkeit.value = '';
        }
    }


    const resetFormValidity = (e: ChangeEvent<HTMLElement>) => {

        (e.target as HTMLInputElement).setCustomValidity('');
        setValidated(false);
    }

    const formRef = useRef<HTMLFormElement>(null);

    const requiredIcon = <FaAsterisk style={{ color: 'red', paddingLeft: '2px' }} ></FaAsterisk>
    const rowClassName = 'py-2';
    return <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <Container fluid='md' >
            <Row className={rowClassName}>
                <Col className={rowClassName} xs={12} md={4}>
                    <Form.Group>
                        <Form.Label>Art{requiredIcon}</Form.Label>
                        <Form.Select name='art' required onChange={handleArtChange}>
                            <option value="-1">Art wählen</option>
                            <option value="0">Beschäftigungsverhältnis</option>
                            <option value="1">Aus- und Weiterbildung</option>
                            <option value="2">Arbeitslos</option>
                            <option value="3">Selbständig</option>
                            <option value="4">nicht zutreffend (Lücke)</option>
                            <option value="2">Pension</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                            {artInvalidMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className={rowClassName} xs={12} md={4}>
                    <Form.Group>
                        <Form.Label>Organisation{requiredIcon}</Form.Label>
                        <Form.Control name='organisation' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
                <Col className={rowClassName} xs={12} md={4}>
                    <Form.Group>
                        <Form.Label>Tätigkeit{requiredIcon}</Form.Label>
                        <Form.Control name='taetigkeit' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={rowClassName}>
                <Col className={rowClassName} xs={12} md={6}>
                    <Form.Group>
                        <Form.Label>von{requiredIcon}</Form.Label>
                        <Form.Control name='von' type='date' placeholder='YYYY-MM-DD' max={moment().format('YYYY-MM-DD')} required onChange={resetFormValidity} ></Form.Control>
                        <Form.Control.Feedback type="invalid" >
                            {fromInvalidMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className={rowClassName} xs={12} md={6}>
                    <Form.Group>
                        <Form.Label>bis (bei aktueller Beschäftigung leer)</Form.Label>
                        <Form.Control name='bis' type='date' placeholder='YYYY-MM-DD' max={moment().format('YYYY-MM-DD')} onChange={resetFormValidity} ></Form.Control>
                        <Form.Control.Feedback type="invalid" >
                            {toInvalidMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={rowClassName}>
                <Col>
                    <Button onClick={props.callbackAbort} variant="secondary">Abbrechen</Button>
                </Col>
                <Col>
                    <Button type="submit"  variant="success" className="btn-lg" >Speichern</Button>
                </Col>
            </Row>
        </Container>
    </Form>

}