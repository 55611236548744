import { FaAsterisk } from '@react-icons/all-files/fa/FaAsterisk';
import moment from 'moment';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { StatesAlpha2List } from '../statesAlpha2List';

export type Wohnsitz = {
    land: string,
    plz: string,
    ort: string,
    strasse: string,
    hausnummer: string,
    von: Date,
    bis?: Date
}

export type AddWohnsitzComponentProps = {
    callbackOnAdd: (wohnsitz: Wohnsitz) => void,
    callbackAbort: () => void
}

function AddWohnsitzComponent(props: Readonly<AddWohnsitzComponentProps>) {


    const [validated, setValidated] = useState(false);
    const [fromInvalidMessage, setFromInvalidMessage] = useState('');
    const [toInvalidMessage, setToInvalidMessage] = useState('');
    const [landInvalidity, setLandInvalidity] = useState('');

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {

            let correct = true;

            if (form.land.value === '') {
                form.land.setCustomValidity('Bitte Land auswählen');
                setLandInvalidity('Bitte Land auswählen');
                correct = false;
            }

            const vonDate = moment(form.von.value);
            if (!vonDate.isValid()) {
                setFromInvalidMessage('Von Datum muss im Format YYYY-MM angegeben werden.');
                form.von.setCustomValidity('VonDatum');
                correct = false;
            }
            else if (vonDate.isAfter(moment())) {
                setFromInvalidMessage('Von Datum darf nicht in der Zukunft liegen.');
                form.von.setCustomValidity('VonDatum');
                correct = false;
            }
            else {
                form.von.setCustomValidity('');
            }
            let bisDate = null;
            if (form.bis.value)
                bisDate = moment(form.bis.value);


            if (bisDate != null && !bisDate.isValid()) {
                setToInvalidMessage('Bis Datum muss im Format YYYY-MM angegeben werden oder leer sein, wenn Wohnsitz aktuell ist.');
                correct = false;
            }
            else if (bisDate != null && bisDate.isBefore(vonDate)) {
                setToInvalidMessage('Bis Datum muss nach Von-Datum liegen.');
                correct = false;
            }

            const wohnsitz: Wohnsitz = {
                land: form.land.value.toUpperCase(),
                plz: form.plz.value,
                ort: form.ort.value,
                strasse: form.strasse.value,
                hausnummer: form.hausnummer.value,
                von: vonDate.date(1).toDate(),
                bis: bisDate?.date(28).toDate()

            }
            debugger;
            if (correct)
                props.callbackOnAdd(wohnsitz);
        }

        setValidated(true);

    };

    const resetFormValidity = (e: ChangeEvent<HTMLElement>) => {
        const element = e.target as HTMLInputElement;
        if (element.value >= '0') {
            element.setCustomValidity('');
            //setValidated(false);
        }
        else {
            element.setCustomValidity('Wert auswählen');
        }
    }

    const requiredIcon = <FaAsterisk style={{ color: 'red', paddingLeft: '2px' }} ></FaAsterisk>
    const rowClassName = 'py-2';
    return <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container fluid='md' >
            <Row className={rowClassName}>
                <Col md={3} xs={12}>
                    <Form.Group>
                        <Form.Label>Land{requiredIcon}</Form.Label>
                        <Form.Select name='land' required onChange={resetFormValidity}>
                            <option value={''}>Bitte Land wählen</option>
                            {
                                Object.keys(StatesAlpha2List.ALPHA2CODES).map((key, index) =>
                                    <option value={key}>{StatesAlpha2List.ALPHA2CODES[key]}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' >
                            {landInvalidity}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col md={3} xs={12}>
                    <Form.Group>
                        <Form.Label>PLZ{requiredIcon}</Form.Label>
                        <Form.Control name='plz' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Form.Label>Ort{requiredIcon}</Form.Label>
                        <Form.Control name='ort' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={rowClassName}>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Form.Label>Straße{requiredIcon}</Form.Label>
                        <Form.Control name='strasse' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Form.Label>Hausnummer{requiredIcon}</Form.Label>
                        <Form.Control name='hausnummer' type='text' required></Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={rowClassName}>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Form.Label>von{requiredIcon}</Form.Label>
                        <Form.Control name='von' type='date' placeholder='YYYY-MM-DD' max={moment().format('YYYY-MM-DD')} required onChange={(e) => e.target.setCustomValidity('')} ></Form.Control>
                        <Form.Control.Feedback type='invalid' >
                            {fromInvalidMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Form.Label>bis (bei aktuellem Wohnsitz leer)</Form.Label>
                        <Form.Control name='bis' type='date' placeholder='YYYY-MM-DD' max={moment().format('YYYY-MM-DD')} onChange={(e) => e.target.setCustomValidity('')}></Form.Control>
                        <Form.Control.Feedback type='invalid' >
                            {toInvalidMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={rowClassName}>
                <Col>
                    <Button onClick={props.callbackAbort} variant='secondary'>Abbrechen</Button>
                </Col>
                <Col>
                    <Button type='submit' variant='success' className='btn-lg' >Speichern</Button>
                </Col>
            </Row>
        </Container>
    </Form>
}

export default AddWohnsitzComponent;